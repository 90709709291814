<template>
  <div>
    <div class="row">
      <div class="col-md-9">
        <div class="card card-custom gutter-b">
          <div class="card-body px-2">
            <DualListBoxDevices
              :source="devices"
              :destination="selectedDevices"
              label="name"
              @onChangeList="onChangeList"
            />
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <DatePicker
              ref="picker"
              v-model="range"
              mode="dateTime"
              :attributes="attributes"
              :masks="masks"
              locale="hr"
              is-range
              is24hr
              is-expanded
              :columns="$screens({ default: 1, lg: 1 })"
              :max-date="new Date()"
            >
            </DatePicker>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <button
          class="btn btn-primary btn-block font-weight-bold"
          @click="exportDevices"
        >
          Export
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import DualListBoxDevices from '@/view/content/components/dual-list-box/DualListBoxDevices'
import ApiService from '../../../core/services/api.service'
import axios from 'axios'
import format from 'date-fns/format'

export default {
  name: 'DeviceFinanceReport',
  components: {
    DatePicker,
    DualListBoxDevices,
  },
  data() {
    return {
      devices: [],
      isDevicesLoaded: false,
      selectedDevices: [],
      range: {
        start: null,
        end: null,
      },
      masks: {
        input: 'dd.MM.yyyy - HH:mm:ss',
      },
      attributes: [
        {
          key: 'today',
          highlight: true,
          dates: null,
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'currentUserRole',
      'currentUserCompany',
      'currentActiveModuleComponents',
    ]),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Export', route: '/finance-report/export' },
    ])

    if (
      this.currentUserRole === 'ROLE_ADMIN' ||
      this.currentUserRole === 'ROLE_WORKER'
    ) {
      ApiService.get(
        'devices',
        `?company=${this.currentUserCompany.replace(
          '/api/v1/companies/',
          '',
        )}&pagination=false`,
      ).then(({ data }) => {
        this.devices = data['hydra:member'].filter(
          (device) =>
            (device.isSaldoReducer !== true) & (device.isDemoDevice !== true),
        )
        this.isDevicesLoaded = true
      })
    }
  },
  methods: {
    onChangeList: function ({ source, destination }) {
      this.devices = source
      this.selectedDevices = destination
    },
    exportDevices() {
      const from = format(this.range.start, "yyyy-MM-dd'T'HH:mm:ss")
      const to = format(this.range.end, "yyyy-MM-dd'T'HH:mm:ss")

      let devices = ''
      this.selectedDevices.forEach((device) => {
        devices += `&devices[]=${device['@id']}`
      })

      axios
        .get(
          `${process.env.VUE_APP_ROUTE_API_URL}/api/v1/finance-report-device-export?oib=112233${devices}&from=${from}&to=${to}`,
        )
        .then(() => {})
    },
  },
}
</script>
